// Dimensions
export const DAY_WIDTH = 7200;
export const HOURS_IN_DAY = 24;

export const HOUR_IN_MINUTES = 60;

export const TIMELINE_HEIGHT = 60;

export const SIDEBAR_WIDTH = 100;
export const ITEM_HEIGHT = 80;

export const ITEM_OVERSCAN = ITEM_HEIGHT;

// Debounce
export const DEBOUNCE_WAIT = 100;
export const DEBOUNCE_WAIT_MAX = 100;

// Program refresh
export const PROGRAM_REFRESH = 120000;

// Theme
export const THEME_MODE = {
  DARK: "dark",
  LIGHT: "light",
};

export const TIME_FORMAT = {
  DEFAULT: "yyyy-MM-dd HH:mm:ss",
  DATE: "yyyy-MM-dd",
  HOURS_MIN: "HH:mm",
  BASE_HOURS_TIME: "h:mm a",
};
